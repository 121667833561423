import React, { useState } from "react";

import styled from 'styled-components'

import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import TextBlock from '../components/textBlock'
import Hero from '../components/hero'
import SectionTitle from '../components/sectionTitle'
import ImageCard from '../components/imageCard'
import ImageContainer from '../components/imageContainer'

const ThingsToDoPage = () => {
    const [getCountry, setGetCountry] = useState(true)
    const data = useStaticQuery(graphql`
        query {
            hero: file(relativePath: { eq: "Engagement-34.jpg" }) {
                childImageSharp {
                fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
                }
                }
            }
            hero2: file(relativePath: { eq: "Engagement-49.jpg" }) {
              childImageSharp {
              fluid(maxWidth: 1440) {
                  ...GatsbyImageSharpFluid
              }
              }
          }
        ttd1: file(relativePath: { eq: "ny-casino.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        ttd2: file(relativePath: { eq: "maidofmist.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        ttd3: file(relativePath: { eq: "canadianfalls.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        ttd4: file(relativePath: { eq: "cliftonhill.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
    }
    `)

    const HeaderContainer = styled.div ({
        backgroundColor: '#fff',
        padding: '24px',
        boxSizing: 'border-box',
        width: 'calc(100% - 32px)',
        display: 'block',
        margin: 'auto',
        'box-shadow': '0px 0px 12px 4px rgba(0, 0, 0, 0.2)',
        borderRadius: '16px',
        marginTop: "-24px",
        position: 'relative',
        'z-index': '10',
      })

      const USAButton = styled.button`
        font-size: 32px;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        border: 1px solid #e3e3e3;
        height: 48px;
        width: calc(50% - 16px);
        margin-left: 16px;
        margin-top: 32px;
        display: inline-block;
        margin-bottom: 24px;
    `

    const CanadaButton = styled.button`
        font-size: 32px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        border: 1px solid #e3e3e3;
        height: 48px;
        width: calc(50% - 16px);
        margin-top: 32px;
        text-align: center;
        display: inline-block;
        margin-bottom: 24px;
    `
    
  return ( 
    <div>
      <Layout>
        <SEO title="Things To Do | Reinhart Mitton" />
        <Hero file={getCountry ? data.hero.childImageSharp.fluid : data.hero2.childImageSharp.fluid} style={{marginTop: '50px'}}/>
        <HeaderContainer>
            <TextBlock
                title={`Things to Do - ${ getCountry ? 'USA' : 'Canada' }`}
                subTitle="What to Do and Where to Eat"
            />
        </HeaderContainer>
        <USAButton onClick={() => setGetCountry(true)} style={getCountry ? {backgroundColor: '#fff'} : {backgroundColor: '#e3e3e3' }}><span role="img" aria-label="USA">🇺🇸</span></USAButton>
        <CanadaButton onClick={() => setGetCountry(false)} style={!getCountry ? {backgroundColor: '#fff'} : {backgroundColor: '#e3e3e3' }}><span role="img" aria-label="Canada">🇨🇦</span></CanadaButton>
        

        { getCountry && 
        <>
            <ImageContainer>
                <ImageCard name="Seneca Niagara Casino" image={data.ttd1.childImageSharp.fluid}/>
                <ImageCard name="Maid of the Mist" image={data.ttd2.childImageSharp.fluid}/>
            </ImageContainer>
            <SectionTitle title="Where to Eat" style={{ margin: "24px 16px", display: "block" }}/>
        </>
        }
        { !getCountry &&
        <>
        <ImageContainer>
            <ImageCard name="Falls View Canada" image={data.ttd3.childImageSharp.fluid}/>
            <ImageCard name="Clifton Hill Canada" image={data.ttd4.childImageSharp.fluid}/>
        </ImageContainer>
        <SectionTitle title="Where to Eat" style={{ margin: "24px 16px", display: "block" }}/>
        </>
        } 
        </Layout>
    </div>     
  )
}

export default ThingsToDoPage